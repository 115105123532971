<template>
  <div class="containerAttachmentButton" :class="{'containerAttachmentButton_disabled': disabled}">
    <input multiple type="file" accept="image/*" class="containerAttachmentButton__inputFile" @change="$emit('click', $event)" :disabled="disabled">
    <span class="containerAttachmentButton__title">Прикрепить файл</span>

    <div class="containerAttachmentButton__iconPlus">
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99935 2.66602V7.99935M7.99935 13.3327V7.99935M7.99935 7.99935H13.3327M7.99935 7.99935H2.66602" stroke-width="1.5" stroke-linejoin="round"/>
      </svg>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AttachmentButton",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
        'GET_COUNT_OF_MATCHES'
    ])
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
@import "src/scss/vars/mixins";
@import "src/scss/vars/colors";

  .containerAttachmentButton {
    cursor: pointer;
    border-radius: 20px;
    border: 1px solid $grey;
    display: flex;
    flex-direction: row;
    padding: 9px 16px;
    align-items: center;
    position: relative;

    &_disabled {
      pointer-events: none;
      opacity: .5;
    }

    &__inputFile {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &::-webkit-file-upload-button {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }

    &__title {
      text-transform: uppercase;
      color: $grey;
      letter-spacing: 0.002em;
      font-size: 14px;
      line-height: 100%;
      font-family: $blatant-cyrillic, $blatant-latin;
    }

    &__iconPlus {
      margin-left: 2px;
      width: 16px;
      height: 16px;

      min-height: 16px;
      min-width: 16px;

      svg {
        width: 100%;
        height: 100%;

        path {
          stroke: $yellow;
        }
      }
    }
  }
</style>
<template>
  <div>
    {{displayDate}}
  </div>
</template>

<script>
import {getDHMSValuesObject} from "@/otherJS/secondsToDHMS";

export default {
  name: "CountDownTimer",
  props: {
    seconds: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      innerSeconds: 0
    }
  },
  computed: {
    displayDate() {
      const date = getDHMSValuesObject(this.innerSeconds);

      return `${date.days.formatted}${date.hours.formatted}${date.minutes.formatted}${date.seconds.formatted}`
    },
  },
  methods: {
    init() {
      /*Если к нам приходит сразу отрицательное время, то мы эмитим ивент конца*/
      if (this.innerSeconds < 0) {
        this.$emit('ended');
        return
      }

      const timer = setInterval(() => {
        this.innerSeconds--;

        if (this.innerSeconds < 0) {
          clearInterval(timer);
          this.$emit('ended');
        }
      }, 1000);
    }
  },

  created() {
    this.innerSeconds = this.seconds;

    this.init();
  }
}
</script>

<style scoped>

</style>
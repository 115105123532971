export const arrMethods = {
    methods: {
        /**
         * Собираем массив со счетом. Если счет пустой, то собираем пустой массив
         * @param num - число, равное кол-ву раундов.
         * @param score - массив со счетами
         * */
        createScoreArr(num, score = [], markers) {
            return score.length ? this.createScoreArrFromScore(score, markers) : this.createEmptyScoreArr(num)
        },
        createEmptyScoreArr(num) {
            let arr = [];

            for(let i = 0; i < num; i++) {
                arr.push({
                    value: '',
                    disabled: false,
                    placeholder: '',
                    marker: ''
                });
            }
            return arr;
        },
        createScoreArrFromScore(score, markers) {
            let arr = [];

            for(let i = 0; i < score.length; i++) {
                arr.push({
                    value: score[i],
                    disabled: score[i] === null || '',
                    placeholder: '-',
                    marker: markers[i]
                });
            }
            return arr;
        },
        changeScorePlaceholder(score, alterable, newItem) {
            /*
              alterable - заменяемое значение
              newItem - чем заменяем
            */
            for (let i = 0; i < score.length; i++) {
                if (score[i].placeholder === alterable) {
                    score[i].placeholder = newItem
                }
            }
            return score;
        },
    }
}
